<template>

  <form>

    <b-message title="How to add Sitemaps" type="is-info" has-icon icon="mapbox" aria-close-label="Close message">
      Each level has its own list of rasterfiles, uploadable through <a target="_blank"
                                                                        href="https://studio.mapbox.com">Mapbox Studio >
      Tilesets</a>.
      You can upload .mbtiles files of sitemaps there as a custom raster. The generation of tilesets can be done using
      <a target="_blank" href="https://studio.mapbox.com">Maptiler</a>.
      You need to copy the private tileset ID and insert it as "mapbox://{tileset_id}". If you need multiple rasters on
      the same floor, you can comma-seperate them. The first raster
      will be drawn on top. (E.g. mapbox://adzdza,mapbox://kefaz)
    </b-message>

    <b-table :data="maplevels">

      <b-table-column label="Level"
                      :width="50"
                      :numeric="true"
                      v-slot="props">
        {{ props.row.level }}
      </b-table-column>

      <b-table-column label="Name"
                      :width="200"
                      v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column label="Mapbox raster url" v-slot="props">
        {{ props.row.style_url }}
      </b-table-column>

      <b-table-column v-slot="props">
        <b-button @click="removeLevel(props.index)" icon-left="delete" type="is-danger"></b-button>
      </b-table-column>

      <template slot="empty">
        <small>Use the bar below to enter new levels and their corresponding styles.</small>
      </template>

      <template slot="footer">
        <th class="is-hidden-mobile">
          <b-field>
            <b-input placeholder="0" v-model="currentlevel"></b-input>

          </b-field>
        </th>
        <th class="is-hidden-mobile">
          <b-field>
            <b-input placeholder="Description" v-model="currentname"></b-input>
          </b-field>
        </th>
        <th class="is-hidden-mobile">
          <b-field>
            <b-input placeholder="mapbox://..." v-model="currenturl"></b-input>
          </b-field>
        </th>
        <th class="is-hidden-mobile">
          <div class="buttons right">
            <b-button @click="addLevel" icon-left="plus-circle" type="is-success"></b-button>
          </div>
        </th>
      </template>
    </b-table>

    <hr>
    <b-notification type="is-warning is-light">Temporary: If you want to edit the raster url of ground floor, you can
      simply delete and re-add it.
      Make sure ground 0 is there!
    </b-notification>

    <hr>
    <div slot="footer">
      <b-field horizontal>
        <b-field grouped position="is-right">
          <div class="control">
            <b-button @click="submit" type="is-primary">Start planning!</b-button>
          </div>
        </b-field>
      </b-field>
    </div>


  </form>
</template>

<script>
import deplo from "../store/deplo.store"
import CardComponent from "@/components/CardComponent";

export default {
  name: "MaplevelFormComponent",
  components: {CardComponent},
  props: {
    id: {
      default: null
    },
    popup: {
      default: true
    }
  },


  data() {
    return {

      maplevels: [],

      currentlevel: null,
      currentname: null,
      currenturl: null

    }
  },

  computed: {
    creating() {
      return this.id === null
    },

  },
  created() {

    if (!deplo.isRegistered) {
      this.$store.registerModule('deplo', deplo)
      deplo.isRegistered = true
    }
  },

  mounted() {
    if (this.id === null) {
      // New project
    } else {
      // Fetch project data
      let formData = {
        id: this.id,
        params: ""
      }
      this.$store.dispatch('deplo/fetchMapLevels', this.id)
          .then(response => {
            this.maplevels = response.data.data
          })

    }
  },

  methods: {
    submit() {
      if(this.maplevels.length === 0) {
        this.$buefy.toast.open({
          message: 'At least one floor is required!',
          type: 'is-danger',
          queue: false
        })
      } else {
        this.$emit("close")
      }
    },
    addLevel() {
      if (this.currentlevel) {

        this.$store.dispatch('deplo/createMapLevel', {
          project_id: this.id,
          level: this.currentlevel,
          name: this.currentname,
          style_url: this.currenturl
        }).then(response => {
          this.maplevels.push({
            id: response.data.data.id,
            level: this.currentlevel,
            name: this.currentname,
            style_url: this.currenturl
          })
          this.currentlevel++
          this.currentname = null
          this.currenturl = null

        }).catch(err => {
          this.$buefy.toast.open({
            message: err,
            type: 'is-danger'
          })
        })

      }
    },
    removeLevel(index) {
      this.$store.dispatch('deplo/deleteMapLevel', {
        project_id: this.id,
        id: this.maplevels[index].id,
      }).then(response => {
        if (index > -1) {
          this.maplevels.splice(index, 1);
        }
      })

    }
  }

}
</script>

<style lang="scss" scoped>

.map-section-project {
  height: 200px;

}

#projectformap {
  width: 100%;
  height: 100%;
}

</style>