<template>
  <section class="section is-main-section">
    <title-bar :title-stack="titleStack"/>


    <div class="columns is-multiline">
      <div v-for="site in projects" class="column is-one-third-desktop is-half">
        <div class="card">
          <div class="card-image">
            <figure class="image is-4by2">
              <img :src="url(site.long, site.lat)" alt="Placeholder image">
            </figure>
          </div>
          <div class="card card__one" style="cursor:pointer">
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-5">{{ site.name }}</p>
                  <p class="subtitle is-6">{{ site.address }} {{ site.nr }} {{ site.town }}</p>
                </div>
              </div>
            </div>
            <footer class="card-footer">
              <router-link :to="{ name:'mapitems.overview', params: {project_id: site.id}}" class="card-footer-item">
                View
              </router-link>
              <a class="card-footer-item" @click.stop="openFormModal(site.id)">Edit</a>
            </footer>
          </div>
        </div>

      </div>
      <div class="column is-one-third-desktop is-half">
        <div class="card">
          <div @click="openFormModal(null)" class="card card__one" style="cursor:pointer">
            <div class="card-content has-text-centered">
              <b-icon icon="plus-circle" size="is-large"></b-icon>
            </div>

          </div>
        </div>
      </div>
    </div>


  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import projects from "@/store/modules/projects.store";
import DeploProjectFormComponent from "@/components/deplo/components/DeploProjectFormComponent";

export default {
  name: "DeploProjects",
  components: {TitleBar, DeploProjectFormComponent},
  computed: {
    titleStack() {
      return [
        'Deplo Projects'
      ]
    },
    projects() {
      return this.$store.getters["projects/getDeploProjects"]
    }
  },
  created() {
    if (!projects.isRegistered) {
      this.$store.registerModule('projects', projects)
      projects.isRegistered = true
    }
  },
  beforeMount() {
    this.$store.dispatch('projects/fetchProjects')
    this.$store.commit('settings/deploToggle', true)
  },
  methods: {

    url(long, lat) {
      return 'https://api.mapbox.com/styles/v1/luckasdc/ck7lt4eao0up71in4wu61e03u/static/' + long + ',' + lat + ',15.4/400x200@2x?access_token=pk.eyJ1IjoibHVja2FzZGMiLCJhIjoiY2sxdWR6d3B5MDkwOTNpcGFuNXh3dGhzNSJ9.nBYgv6LPBGFGDx56p8mRQQ'
    },

    openFormModal(id) {
      this.$buefy.modal.open({
        parent: this,
        component: DeploProjectFormComponent,
        props: {
          id: id
        },
        hasModalCard: true,
        trapFocus: false,
        ariaRole: "dialog",
        ariaModal: true,
        scroll: "keep"
      })
    },
  },


}
</script>

<style lang="scss">


.card__one {
  transition: transform .2s;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity 1s cubic-bezier(.165, .84, .44, 0.5);
    box-shadow: 0 5px 12px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
    content: '';
    opacity: 0;
    z-index: -1;
  }

  &:hover,
  &:focus {
    transform: scale3d(1.006, 1.006, 1);

    &::after {
      opacity: 1;
    }
  }
}

</style>
