<template>
  <card-component :title="this.creating ? 'Create Deplo Project' : 'Edit Deplo Project'"
                  :icon="this.creating ? 'plus-circle' : 'pencil'"
                  :is-scrollable="this.popup"
                  :has-footer-slot="true"
                  style="min-width: 50vw">
    <form v-if="!secondTab" @submit.prevent="submit">
      <b-field label="Project name" horizontal>
        <b-field>
          <b-input v-model="project.name" placeholder="Name" name="name" required />
        </b-field>
      </b-field>
      <b-field v-if="this.companies"  label="Company" horizontal>
        <b-autocomplete v-bind="{'disabled' : !this.creating}"
                        v-model="companyname"
                        placeholder="e.g. MyCompany"
                        ref="autocomplete"
                        :open-on-focus="true"
                        :data="filteredDataObj"
                        field="name"
                        @select="option => project.company_id = option.id">
          <template slot="header">
            <a @click="newCompany">
              <span> Add new... </span>
            </a>
          </template>
          <template #empty>No results for {{companyname}}</template>

        </b-autocomplete>
      </b-field>
      <hr>

          <mapbox style="height: 200px" ref="projectformmap" :access-token="accessToken" :map-options="mapStyle"
                  :geolocate-control="{
                              show: true,
                              position: 'top-right',
                            }"
                  @map-init="mapInitialized">
          </mapbox>
          <hr>
          <b-field label="Location" horizontal>
            <b-field>
              <b-input v-model="project.long" placeholder="Longitude" name="long" required @input="updateLocation"/>
            </b-field>
            <b-field>
              <b-input v-model="project.lat" placeholder="Latitude" name="lat" required @input="updateLocation"/>
            </b-field>
          </b-field>
          <hr>

          <b-field label="Address" horizontal>
            <b-field>
              <b-input v-model="project.address" placeholder="Street" name="street" required />
            </b-field>
          </b-field>
          <b-field horizontal>
            <b-field>
              <b-input v-model="project.pc" placeholder="Postal Code" name="pc" required />
            </b-field>
            <b-field>
              <b-input v-model="project.town" placeholder="Town" name="town" required />
            </b-field>
          </b-field>


      <hr>
      <div slot="footer">
        <b-field horizontal  >
          <b-field grouped position="is-right">
            <div class="control" v-if="popup">
              <b-button type="is-primary is-outlined" @click="this.$parent.close">Cancel</b-button>
            </div>
            <div class="control">
              <b-button native-type="submit" type="is-primary">{{ this.creating ? "Create" : "Update" }}</b-button>
            </div>
          </b-field>
        </b-field>
      </div>


    </form>

    <div v-if="secondTab">
      <maplevel-form-component :id="project_id" @close="finished"></maplevel-form-component>
    </div>
  </card-component>
</template>

<script>
import projects from "@/store/modules/projects.store"
import users from "@/store/modules/users.store"

import CardComponent from "@/components/CardComponent";
import Mapbox from 'mapbox-gl-vue'
import MaplevelFormComponent from "@/components/deplo/components/MaplevelFormComponent";
const GeoJSON = require('geojson');


export default {
  name: "ProjectFormComponent",
  components: {MaplevelFormComponent, CardComponent, Mapbox},
  props: {
    id: {
      default: null
    },
    popup: {
      default: true
    }
  },


  data() {
    return {
      accessToken: 'pk.eyJ1IjoibHVja2FzZGMiLCJhIjoiY2sxdWR6d3B5MDkwOTNpcGFuNXh3dGhzNSJ9.nBYgv6LPBGFGDx56p8mRQQ',
      mapStyle: {
        style: 'mapbox://styles/luckasdc/ckf29lnav0mm319qvg7u55h7l',
        container: "projectformmap"
      },

      themap: {},

      activeTab: 0,

      project: {
        name: "",
        address: "",
        town: "",
        pc: "",
        long: 4.64,
        lat: 50.44,
        twowayverification: false,
        company_id: 0,
        isDeplo: true,
        maplevels: []
      },
      companyname: "",
      marker: {},

      secondTab: false,
      project_id: null,

      currentlevel: null,
      currentname: null,
      currenturl: null

    }
  },

  computed: {
    creating () {
      return this.id === null
    },
    companies() {
      return this.$store.state.users.companies
    },

    filteredDataObj() {
      return this.companies.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.companyname.toLowerCase()) >= 0
      })
    }

  },
  created() {
    if(!projects.isRegistered) {
      this.$store.registerModule('projects', projects)
      projects.isRegistered = true
    }
    if(!users.isRegistered) {
      this.$store.registerModule('users', users)
      users.isRegistered = true
    }
  },

  mounted() {
    if (this.id === null) {
      // New project
      this.$store.dispatch('users/fetchCompanies')
    }
    else {
      // Fetch project data
      let formData = {
        id: this.id,
        params: ""
      }
      this.$store.dispatch('projects/fetchProjectDetails', formData)
          .then(response => {
            this.project = JSON.parse(JSON.stringify(response.data.data))
            this.marker.setLngLat([this.project.long, this.project.lat]).addTo(this.themap);
            this.themap.panTo(this.marker._lngLat, {animate: false, zoom: 14})
            this.companyname = this.project.company.name
          })

    }

  },

  methods: {
    mapInitialized(map) {
      this.themap = map

      this.marker = new mapboxgl.Marker({
        draggable: true
      })
          .setLngLat([this.project.long, this.project.lat])
          .addTo(this.themap);

      this.marker.on('dragend', this.dragged)
      this.themap.scrollZoom.disable();
      this.themap.jumpTo({center: [this.project.long, this.project.lat], zoom: 6 })



    },
    dragged(marker) {
      let lngLat = marker.target._lngLat
      this.project.long = lngLat.lng
      this.project.lat = lngLat.lat
    },

    updateLocation() {
      this.marker.setLngLat([this.project.long, this.project.lat])
          .addTo(this.themap);
    },

    newCompany() {
      this.$buefy.dialog.prompt({
        message: `What's the company name?`,
        inputAttrs: {
          placeholder: 'e.g. MyCompany',
          maxlength: 50
        },
        trapFocus: true,
        onConfirm: (value) => this.submitNewCompany({name: value})
      })

    },
    submitNewCompany(value) {
      this.$store.dispatch('users/createCompany', value)
          .then(response => {
            this.$buefy.toast.open({
              message: 'Company created',
              type: 'is-success'
            })

            this.$refs.autocomplete.setSelected(response.data)

          }).catch(err => {
        this.$buefy.toast.open({
          message: err,
          type: 'is-danger'
        })
      })
    },

    submit() {
      if (this.creating && this.popup) {
        this.$store.dispatch('projects/createProject', this.project)
            .then(response => {

              this.$buefy.toast.open({
                message: 'Project created',
                type: 'is-success'
              })
              this.project_id = response.data.data.id
              this.secondTab = true

            }).catch(err => {
          this.$buefy.toast.open({
            message: err,
            type: 'is-danger'
          })
        })
      }
      else {
        // Update
        this.$store.dispatch('projects/updateProject', {id: this.id, payload: this.project})
            .then(response => {

              this.$buefy.toast.open({
                message: 'Project updated',
                type: 'is-success'
              })
              this.project_id = response.data.data.id
              this.secondTab = true

            }).catch(err => {
          this.$buefy.toast.open({
            message: err,
            type: 'is-danger'
          })
        })


      }

    },
    addLevel() {
      if(this.currenturl && this.currentlevel) {
        this.project.maplevels.push({
          level: this.currentlevel,
          name: this.currentname,
          style_url: this.currenturl
        })
        this.currentlevel++
        this.currentname = null
        this.currenturl = null
      }
    },
    removeLevel(index) {
      if (index > -1) {
        this.project.maplevels.splice(index, 1);
      }
    },
    finished() {
      this.$router.push({ name:'mapitems.overview', params: {project_id: this.project_id} })
      this.$parent.close()
    }
  }

}
</script>

<style lang="scss"scoped>

.map-section-project{
  height: 200px;

}
#projectformap {
  width: 100%;
  height: 100%;
}

</style>